<template>
  <form
    action="."
    method="POST"
    @submit.prevent="{}"
  >
    <div class="columns is-mobile is-vcentered mb-0" ref="nameAndDescriptionFields">
      <div class="column is-half">
        <!-- Name -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_name") }}</label>
          <div class="control">
            <input
              class="input is-simple"
              type="text"
              :placeholder="$t('exhibitor_form.exhibitor_name_placeholder')"
              v-model="name"
            />
          </div>
          <template v-if="$v.$dirty">
            <p v-if="$v.name && !$v.name.required" class="help is-danger">{{ $t("common.field_required_error") }}</p>
          </template>
        </div>
      </div>
      <div class="column is-half">
        <!-- Scope -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_scope") }}</label>
          <div class="control">
            <input
              class="input is-simple"
              type="text"
              :placeholder="$t('exhibitor_form.exhibitor_scope_placeholder')"
              v-model="scopeDescription"
            />
          </div>
        </div>
      </div>
    </div>

    <template v-if="isDefaultLocale">
      <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_company_logo") }}</label>
      <image-attachment-file-input
        v-model="attachmentPicture"
        accept=".jpg,.png"
      ></image-attachment-file-input>
      <p><strong>{{ $t('exhibitor_form.exhibitor_picture_ratio_hint') }}</strong></p>
    </template>

    <template v-if="isDefaultLocale">
      <label class="label has-text-primary mt-1">{{ $t("exhibitor_form.exhibitor_vip_image") }}</label>
      <image-attachment-file-input
        v-model="attachmentVipPicture"
        accept=".jpg,.png"
      ></image-attachment-file-input>
      <p><strong>{{ $t('exhibitor_form.exhibitor_vip_picture_ratio_hint') }}</strong></p>
    </template>

    <div class="field mt-1">
      <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_description") }}</label>
      <div class="control">
        <textarea class="textarea" v-model="description"></textarea>
      </div>
    </div>

    <div class="field" v-if="isDefaultLocale">
      <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_tags") }}</label>
      <div class="control">
        <dropdown-select :items="exhibitorTags" :multiple="true" :value="selectedTagsIndexes" @input="selectTags">
          <!-- Path tag items -->
          <template #dropdown="{ item }">
            <div class="mr-1">
              <point-icon class="point-icon mr-1" :style="{ fill: item.hex }"></point-icon>
              <span>{{ item.value.toUpperCase() }}</span>
            </div>
          </template>

          <template #trigger="{ item }">
            <div class="mr-1">
              <point-icon class="point-icon mr-1" :style="{ fill: item.hex }"></point-icon>
              <span>{{ item.value.toUpperCase() }}</span>
            </div>
          </template>

          <!-- Placeholder text -->
          <template #placeholder>
            <span>{{ $t("exhibitor_form.exhibitor_tags_placeholder") }}</span>
          </template>
        </dropdown-select>
      </div>
    </div>

    <div class="field" v-if="isDefaultLocale">
      <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_website") }}</label>
      <div class="control">
        <input class="input is-simple" type="text" :placeholder="$t('exhibitor_form.exhibitor_website_placeholder')"
               v-model="www"/>
      </div>
      <template v-if="$v.$dirty">
        <p v-if="!$v.www.optionalUrlValidator" class="help is-danger">{{ $t("common.invalid_url_error") }}</p>
      </template>
    </div>

    <div class="field" v-if="isDefaultLocale">
      <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_video_streaming_url") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="text"
          :placeholder="$t('exhibitor_form.exhibitor_website_placeholder')"
          v-model="videoCallUrl"
        />
      </div>
      <template v-if="$v.$dirty">
        <p v-if="!$v.videoCallUrl.optionalUrlValidator" class="help is-danger">{{ $t("common.invalid_url_error") }}</p>
      </template>
    </div>

    <div class="field" v-if="isDefaultLocale">
      <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_promotion_video_url") }}</label>
      <div class="control">
        <input
          class="input is-simple"
          type="text"
          :placeholder="$t('exhibitor_form.exhibitor_website_placeholder')"
          v-model="promotionVideoUrl"
        />
      </div>
      <template v-if="$v.$dirty">
        <p v-if="!$v.promotionVideoUrl.optionalUrlValidator" class="help is-danger">{{
            $t("common.invalid_url_error")
          }}</p>
      </template>
    </div>

    <div class="is-flex is-flex-direction-row mt-3" v-if="isDefaultLocale">
      <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_contact_information") }}</label>

      <!-- Show contact informations -->
      <div class="field ml-2">
        <b-switch size="is-small" v-model="forLoggedInContact"> {{
            $t("exhibitor_form.exhibitor_only_for_logged_in")
          }}
        </b-switch>
      </div>
    </div>

    <div class="columns is-mobile is-vcentered mb-0 mt-1" ref="nameAndDescriptionFields" v-if="isDefaultLocale">
      <div class="column is-half">
        <!-- Email -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_email") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('exhibitor_form.exhibitor_email')"
                   v-model="email"/>
          </div>
          <template v-if="$v.$dirty">
            <p v-if="!$v.email.email" class="help is-danger">{{ $t("auth.error_email") }}</p>
          </template>
        </div>
      </div>
      <div class="column is-half">
        <!-- Phone number -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_phone_number") }}</label>
          <div class="columns mb-0 pb-0 is-mobile is-gapless mt-1">
            <div class="column is-two-fifths mr-1">
              <number-phone-prefix-select :value="phonePrefix" @input="selectPhonePrefix"></number-phone-prefix-select>
            </div>
            <div class="column">
              <div class="control">
                <input
                  class="input is-simple"
                  type="tel"
                  :placeholder="$t('exhibitor_form.exhibitor_phone_number')"
                  v-model="phoneNumber"
                />
              </div>
            </div>
          </div>
          <template v-if="$v.$dirty">
            <p v-if="!$v.phoneNumberObject.optionalPhoneNumber" class="help is-danger">{{ $t("edit_profile.phone_number_invalid") }}</p>
          </template>
        </div>
      </div>
    </div>

    <div class="is-flex is-flex-direction-row mt-3" v-if="isDefaultLocale">
      <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_social_media") }}</label>

      <!-- Show social media for logged in -->
      <div class="field ml-2">
        <b-switch size="is-small" v-model="forLoggedInSocialMedia"> {{
            $t("exhibitor_form.exhibitor_only_for_logged_in")
          }}
        </b-switch>
      </div>
    </div>

    <div class="columns is-mobile is-vcentered is-multiline mb-0 mt-1" ref="nameAndDescriptionFields"
         v-if="isDefaultLocale">
      <div class="column is-half">
        <!-- Facebook -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_fb") }}</label>
          <div class="control">
            <input
              class="input is-simple"
              type="text"
              :placeholder="$t('exhibitor_form.exhibitor_fb_placeholder')"
              v-model="facebookProfile"
            />
          </div>
          <template v-if="$v.$dirty">
            <p v-if="!$v.facebookProfile.optionalUrlValidator" class="help is-danger">{{
                $t("common.invalid_url_error")
              }}</p>
          </template>
        </div>
      </div>
      <div class="column is-half">
        <!-- LinkedIn -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_linked_in") }}</label>
          <div class="control">
            <input
              class="input is-simple"
              type="text"
              :placeholder="$t('exhibitor_form.exhibitor_linked_in_placeholder')"
              v-model="linkedInProfile"
            />
          </div>
          <template v-if="$v.$dirty">
            <p v-if="!$v.linkedInProfile.optionalUrlValidator" class="help is-danger">{{
                $t("common.invalid_url_error")
              }}</p>
          </template>
        </div>
      </div>
      <div class="column is-half">
        <!-- Twitter -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_twitter") }}</label>
          <div class="control">
            <input
              class="input is-simple"
              type="text"
              :placeholder="$t('exhibitor_form.exhibitor_twitter_placeholder')"
              v-model="twitterProfile"
            />
          </div>
          <template v-if="$v.$dirty">
            <p v-if="!$v.twitterProfile.optionalUrlValidator" class="help is-danger">{{
                $t("common.invalid_url_error")
              }}</p>
          </template>
        </div>
      </div>
      <div class="column is-half">
        <!-- YouTube -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_youtube") }}</label>
          <div class="control">
            <input
              class="input is-simple"
              type="text"
              :placeholder="$t('exhibitor_form.exhibitor_youtube_placeholder')"
              v-model="youtubeProfile"
            />
          </div>
          <template v-if="$v.$dirty">
            <p v-if="!$v.youtubeProfile.optionalUrlValidator" class="help is-danger">{{
                $t("common.invalid_url_error")
              }}</p>
          </template>
        </div>
      </div>
      <div class="column is-half">
        <!-- Instagram -->
        <div class="field">
          <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_instagram") }}</label>
          <div class="control">
            <input
              class="input is-simple"
              type="text"
              :placeholder="$t('exhibitor_form.exhibitor_instagram_placeholder')"
              v-model="instagramProfile"
            />
          </div>
          <template v-if="$v.$dirty">
            <p v-if="!$v.instagramProfile.optionalUrlValidator" class="help is-danger">{{
                $t("common.invalid_url_error")
              }}</p>
          </template>
        </div>
      </div>
    </div>

    <template v-if="isDefaultLocale">
      <label class="label has-text-primary">{{ $t("exhibitor_form.exhibitor_attachments") }}</label>
      <attachments-list-input v-model="attachments"></attachments-list-input>
    </template>

    <template>
      <div class="field mt-1">
        <label class="label has-text-primary">{{ $t("exhibitor_form.extra_note_label") }}</label>
        <div class="control">
          <textarea class="textarea" v-model="extraNote"></textarea>
        </div>
      </div>
    </template>

    <b-button class="button mt-4 is-fullwidth py-3 is-size-4" type="is-success" @click="$emit('submit')">
      {{ $t('exhibitor_form.save_button') }}
    </b-button>
  </form>
</template>

<script>
import {required, email} from "vuelidate/lib/validators";
import {optionalPhoneNumber, optionalUrlValidator} from "@/shared/utils/form-validations";
import DropdownSelect from "@/shared/components/DropdownSelect";
import {mapGetters} from "vuex";
import PointIcon from "@/assets/icons/point.svg";
import NumberPhonePrefixSelect from "@/web/components/forms/NumberPhonePrefixSelect";
import ImageAttachmentFileInput from "@/shared/components/form/ImageAttachmentFileInput";
import AttachmentsListInput from "@/shared/components/form/AttachmentsListInput";

export default {
  name: "ExhibitorUpdateForm",

  components: {
    AttachmentsListInput,
    ImageAttachmentFileInput,
    DropdownSelect,
    PointIcon,
    NumberPhonePrefixSelect,
  },

  props: {
    currentLocale: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      name: "",
      scopeDescription: "",
      description: "",
      forLoggedInSocialMedia: true,
      forLoggedInContact: true,
      phonePrefix: "",
      phoneNumber: "",
      email: "",
      www: "",
      facebookProfile: "",
      linkedInProfile: "",
      twitterProfile: "",
      youtubeProfile: "",
      instagramProfile: "",
      videoCallUrl: "",
      promotionVideoUrl: "",
      inputPictureFile: null,
      attachmentPicture: null,
      inputVipPictureFile: null,
      attachmentVipPicture: null,
      tags: [],
      contactPeople: [],
      contactUsers: [],
      inputAttachmentsFiles: [],
      attachments: [],
      extraNote: "",
    };
  },

  validations() {
    if (this.isDefaultLocale) {
      return {
        name: {required},
        phoneNumberObject: {optionalPhoneNumber},
        email: {
          email: value => email(value && value.toLowerCase().replace(/\s/g, "")),
        },
        www: {optionalUrlValidator},
        facebookProfile: {optionalUrlValidator},
        linkedInProfile: {optionalUrlValidator},
        twitterProfile: {optionalUrlValidator},
        youtubeProfile: {optionalUrlValidator},
        instagramProfile: {optionalUrlValidator},
        videoCallUrl: {optionalUrlValidator},
        promotionVideoUrl: {optionalUrlValidator},
      };
    } else {
      return {};
    }
  },

  computed: {
    ...mapGetters("exhibitorModerator", ["exhibitorTags"]),

    selectedTagsIndexes() {
      return this.tags.map(tag => this.exhibitorTags.findIndex(exhibitorTag => exhibitorTag.id === tag.id));
    },

    isDefaultLocale() {
      return this.currentLocale.isDefault;
    },

    phoneNumberObject() {
      return {phonePrefix: this.phonePrefix, phoneNumber: this.phoneNumber };
    }
  },

  methods: {
    selectPhonePrefix(input) {
      this.phonePrefix = input;
    },
    selectTags(selectedTagIndexes) {
      this.tags = selectedTagIndexes.map(index => this.exhibitorTags[index]);
    },

    isValid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    scrollToError() {
      this.$nextTick().then(() => {
        const input = this.$el.querySelector(".is-danger");
        if (input) {
          input.focus();
          const top = input.offsetTop - 100;
          document.documentElement.scrollTo({top, behavior: 'smooth'});
        }
      });
    },

    getFormData() {
      if (this.currentLocale.isDefault) {
        return {
          name: this.name,
          scope_description: this.scopeDescription,
          description: this.description,
          facebook_profile: this.facebookProfile,
          twitter_profile: this.twitterProfile,
          instagram_profile: this.instagramProfile,
          youtube_profile: this.youtubeProfile,
          linked_profile: this.linkedInProfile,
          for_logged_contact: this.forLoggedInContact,
          for_logged_sm: this.forLoggedInSocialMedia,
          phone: this.phoneNumber,
          phone_country_code: (this.phonePrefix && this.phonePrefix.phonePrefix) || this.phonePrefix,
          email: this.email.toLowerCase().replace(/\s/g, ""),
          www: this.www,
          video_call_url: this.videoCallUrl,
          promotion_film_url: this.promotionVideoUrl,
          exhibitor_attachments: this.attachments,
          picture: this.attachmentPicture && this.attachmentPicture,
          vip_picture: this.attachmentVipPicture && this.attachmentVipPicture,
          extra_note: this.extraNote,
          tags_exhibitors: this.tags,
        };
      } else {
        return {
          name: this.name,
          scope_description: this.scopeDescription,
          description: this.description,
          extra_note: this.extraNote,
        };
      }
    },
  },

  watch: {
    currentLocale: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          if (newValue.isDefault) {
            this.name = this.value.name;
            this.forLoggedInSocialMedia = this.value.for_logged_sm;
            this.forLoggedInContact = this.value.for_logged_contact;
            this.phonePrefix = this.value.phone_country_code;
            this.phoneNumber = this.value.phone;
            this.email = this.value.email;
            this.www = this.value.www;
            this.facebookProfile = this.value.facebook_profile;
            this.linkedInProfile = this.value.linked_profile;
            this.twitterProfile = this.value.twitter_profile;
            this.youtubeProfile = this.value.youtube_profile;
            this.instagramProfile = this.value.instagram_profile;
            this.videoCallUrl = this.value.video_call_url;
            this.promotionVideoUrl = this.value.promotion_film_url;
            this.attachmentPicture = this.value.picture;
            this.attachmentVipPicture = this.value.vip_picture;
            this.tags = this.value.tags_exhibitors;
            this.contactPeople = this.value.exhibitor_contact_people;
            this.contactUsers = this.value.exhibitor_contact_users;
            this.attachments = this.value.exhibitor_attachments;
          }
          let exhibitorTranslations = this.value.translations
            .find(translation => translation.locale.toLowerCase() === newValue.localeCode.toLowerCase()) || (this.value.translations.length && this.value.translations[0]);

          if (exhibitorTranslations) {
            this.name = exhibitorTranslations.name;
            this.description = exhibitorTranslations.description;
            this.scopeDescription = exhibitorTranslations.scope_description;
          }
        }
      },
    },
  },
};
</script>

<style scoped>
</style>

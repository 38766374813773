<template>
  <div class="p-2 is-relative container" v-if="selectedLocale">
    <language-picker
      v-if="eventLocales"
      :available-languages="eventLocales"
      :value="selectedLocale"
      @input="onNewLocaleSelected"
      class="ml-1"
    ></language-picker>
    <exhibitor-update-form v-if="selectedLocale && formInitialValue"
                           ref="exhibitorUpdateForm"
                           :current-locale="selectedLocale"
                           :value="formInitialValue"
                           @submit="submit"
    ></exhibitor-update-form>
    <b-loading v-if="isCreating" :active="isCreating"></b-loading>
  </div>
</template>

<script>
import LanguagePicker from "@/web/components/moderator/creators/LanguagePicker";
import {mapActions, mapGetters} from "vuex";
import ExhibitorUpdateForm from "@/web/components/moderator/creators/ExhibitorUpdateForm";
import Constants from "@/web/constants";
import ExhibitorUpdateModel from "@/web/store/models/ExhibitorUpdateModel";

export default {
  name: "ModeratorExhibitorUpdate",
  components: {ExhibitorUpdateForm, LanguagePicker},
  props: {
    exhibitorId: {
      type: Number,
      required: true,
    },

    exhibitorUpdateId: {
      type: Number,
      required: false,
    },
  },

  mounted() {
    this.initCreator({exhibitorId: this.exhibitorId});
  },

  data() {
    return {
      selectedLocale: null,
      exhibitorData: null,
      exhibitorTranslations: [],
      extraNote: "",
    };
  },

  computed: {
    ...mapGetters("locales", ["eventLocales"]),
    ...mapGetters("exhibitorModerator", ["getExhibitorUpdate", "isCreating", "isCreated"]),
    ...mapGetters("exhibitors", {getExhibitorById: "getById"}),

    editMode() {
      return this.exhibitorUpdateId !== null;
    },

    formInitialValue() {
      if (this.exhibitorData) {
        return {
          ...this.exhibitorData,
          translations: this.exhibitorTranslations,
        };
      } else if (this.exhibitorUpdateId) {
        return this.getExhibitorUpdate(this.exhibitorUpdateId);
      } else {
        let exhibitor = this.getExhibitorById(this.exhibitorId);
        if (exhibitor.translations.length) {
          return exhibitor;
        } else {
          return null;
        }
      }
    },
  },

  methods: {
    ...mapActions("exhibitorModerator", ["initCreator", "createExhibitorUpdate"]),

    onNewLocaleSelected(selectedLocale) {
      let isValid = this.$refs.exhibitorUpdateForm.isValid();
      if (isValid) {
        let currentFormData = this.$refs.exhibitorUpdateForm.getFormData();
        this.setExhibitorDataForLocale(currentFormData, this.selectedLocale);
        this.selectedLocale = selectedLocale;
      } else {
        this.$refs.exhibitorUpdateForm.scrollToError();
      }
    },

    setExhibitorDataForLocale(exhibitorData, locale) {
      if (!this.exhibitorTranslations.length) {
        this.exhibitorTranslations = this.formInitialValue.translations;
      }
      if (locale.isDefault) {
        this.exhibitorData = exhibitorData;
      }
      let foundIndex = this.exhibitorTranslations.findIndex(translation => translation.locale === locale.localeCode);
      let mappedTranslationObject = {
        name: exhibitorData.name,
        description: exhibitorData.description,
        scope_description: exhibitorData.scope_description,
        locale: locale.localeCode,
      };
      this.extraNote = exhibitorData.extra_note;
      if (foundIndex >= 0) {
        this.exhibitorTranslations[foundIndex] = mappedTranslationObject;
      } else {
        this.exhibitorTranslations = [...this.exhibitorTranslations, mappedTranslationObject];
      }
    },

    submit() {
      let isValid = this.$refs.exhibitorUpdateForm.isValid();
      if (isValid) {
        let currentFormData = this.$refs.exhibitorUpdateForm.getFormData();
        this.setExhibitorDataForLocale(currentFormData, this.selectedLocale);
        this.createExhibitorUpdate({
          exhibitorId: this.exhibitorId,
          exhibitorUpdate: this.getExhibitorData(),
          extraNote: this.extraNote,
        }).then(result => {
          if (this.exhibitorUpdateId) {
            if (this.getExhibitorUpdate(this.exhibitorUpdateId).status === Constants.MODERATION_STATUS_PENDING) {
              ExhibitorUpdateModel.delete(this.exhibitorUpdateId);
            }
          }
          this.$router.replace({
            name: Constants.ROUTE_MODERATOR_EXHIBITOR,
            props: {
              exhibitorId: this.exhibitorId,
            },
          });
        });
      } else {
        this.$refs.exhibitorUpdateForm.scrollToError();
      }
    },

    getExhibitorData() {
      let attachments =
        this.exhibitorData.exhibitor_attachments &&
        this.exhibitorData.exhibitor_attachments.length &&
        this.exhibitorData.exhibitor_attachments.map(it => it.id);
      let tags =
        this.exhibitorData.tags_exhibitors &&
        this.exhibitorData.tags_exhibitors.map(it => it.id);
      return {
        ...this.exhibitorData,
        translations: this.exhibitorTranslations.reduce((acc, val) => {
          return {...acc, [val.locale]: val};
        }, {}),
        picture: this.exhibitorData.picture && this.exhibitorData.picture.id,
        vip_picture: this.exhibitorData.vip_picture && this.exhibitorData.vip_picture.id,
        exhibitor_attachments: attachments ? attachments : null,
        tags_exhibitors: tags ? tags : null,
      };
    },
  },

  watch: {
    eventLocales: {
      immediate: true,
      handler: function (newValue) {
        if (newValue && newValue.length && !this.selectedLocale) {
          this.selectedLocale = newValue.find(locale => locale.isDefault);
        }
      },
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="is-relative is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
    <list-loading-indicator :active="true"></list-loading-indicator>
    <div class="title mt-2">
      {{$t("moderator.checking_access_placeholder_text")}}
    </div>
  </div>
</template>

<script>
import ListLoadingIndicator from "@/shared/components/common/loading/ListLoadingIndicator";

export default {
  name: "CheckingAccessPlaceholder",
  components: { ListLoadingIndicator }
};
</script>

<style scoped>

</style>

<template>
  <div class="container is-fluid">
    <div v-if="accessGranted" class="has-fullheight">
      <moderator-accesses class="has-fullheight" v-if="isMainView"></moderator-accesses>
      <router-view class="has-fullheight" v-else></router-view>
    </div>
    <checking-access-placeholder class="has-fullheight" v-else></checking-access-placeholder>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "@/web/constants";
import CheckingAccessPlaceholder from "@/web/components/moderator/placeholders/CheckingAccessPlaceholder";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";
import ModeratorAccesses from "@/web/components/moderator/ModeratorAccesses";

export default {
  name: "ModeratorMain",
  mixins: [AuthorizedRouteMixin],
  components: {
    ModeratorAccesses,
    CheckingAccessPlaceholder,
  },

  data() {
    return {
      accessGranted: false,
    };
  },

  mounted() {
    this.authorizeModerator();
  },

  computed: {
    ...mapGetters("moderatorAccesses", ["isLoaded", "isLoading", "hasAccess"]),

    isMainView() {
      return this.$route.name === Constants.ROUTE_MODERATOR_MAIN;
    },
  },

  methods: {
    ...mapActions("moderatorAccesses", ["fetchAccesses"]),

    authorizeModerator() {
      if (!this.isLoaded) {
        this.fetchAccesses();
      } else if (this.hasAccess) {
        this.accessGranted = true;
      } else {
        this.navigateToIntroduction();
      }
    },

    navigateToIntroduction() {
      this.$router.replace({ name: Constants.ROUTE_MAIN_INTRODUCTION });
    },
  },

  watch: {
    isLoaded: {
      immediate: false,
      handler: function (newValue) {
        if (newValue && this.hasAccess) {
          this.accessGranted = true;
        } else if (newValue && !this.hasAccess) {
          this.navigateToIntroduction();
        }
      },
    },
  },
};
</script>

<style scoped>
</style>
